<template>
  <div class="default-layout">
    <network-status v-if="$platform === 'electron'" />
    <sync v-if="$platform === 'electron' && type === 'standalone'" />
    <navbar />
    <div class="d-flex">
      <sidebar />
      <div class="content">
        <breadcrumbs
          v-if="
            (type !== 'shared' &&
              !$route.name.startsWith('index__') &&
              !$route.name.startsWith('fitness__') &&
              !$route.name.startsWith('regional__')) ||
              (type === 'shared' && applications)
          "
        />
        <nuxt />
        <template v-if="$platform === 'electron'">
          <device-modal />
          <bodyscan-device-modal />
          <device-on-warning-modal />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Navbar from '~/components/navbar.vue'
import Breadcrumbs from '~/components/breadcrumbs'
import Sidebar from '~/components/sidebar'
import NetworkStatus from '~/components/networkStatus'
import Sync from '~/components/sync'
import DeviceModal from '~/components/modals/device'
import BodyscanDeviceModal from '~/components/bodyscan/modals/device'
import DeviceOnWarningModal from '~/components/modals/DeviceOnWarning'

export default {
  components: {
    Breadcrumbs,
    Navbar,
    Sidebar,
    NetworkStatus,
    Sync,
    DeviceModal,
    BodyscanDeviceModal,
    DeviceOnWarningModal
  },
  middleware: ['router', 'auth', 'bodygram'],
  computed: {
    ...mapGetters({
      user: 'user/user',
      settings: 'user/settings',
      applications: 'applications/list',
      type: 'config/type',
      onLine: 'network/onLine',
      offlineModalShown: 'network/offlineModalShown'
    })
  },
  watch: {
    onLine() {
      this.$router.push(this.localePath({ name: 'index' }))
      if (!this.onLine && !this.offlineModalShown) {
        this.openOfflineModal()
      }
    }
  },
  async created() {
    if (!this.settings) {
      await this.getSettings()
    }
    if (!this.applications) {
      await this.getApplications()
    }

    setTimeout(() => {
      if (!this.onLine && !this.offlineModalShown) {
        this.openOfflineModal()
      }
    }, 3000)

    if (
      this.$platform === 'electron' &&
      (this.settings.locale === 'nl_BE'
        ? 'nl-be'
        : this.settings.locale.slice(0, 2)) !== this.$i18n.locale
    ) {
      this.$router.push(
        this.switchLocalePath(
          this.settings.locale === 'nl_BE'
            ? 'nl-be'
            : this.settings.locale.slice(0, 2)
        )
      )
    }

    const locale = this.user ? this.user.locale.slice(0, 2) : 'en'
    this.$dayjs.locale(locale)
  },
  updated() {
    this.$dayjs.locale(this.$i18n.locale)
  },
  methods: {
    ...mapActions({
      getSettings: 'user/getSettings',
      getApplications: 'applications/getApplications'
    }),
    ...mapMutations({
      setOfflineModalShown: 'network/setOfflineModalShown'
    }),
    openOfflineModal() {
      if (this.type !== 'shared') {
        this.setOfflineModalShown(true)
        this.$bvModal.msgBoxOk(this.$t('app.common.oldConfigurations'), {
          title: 'Offline',
          centered: true,
          okVariant: 'danger',
          headerBgVariant: 'danger',
          headerTextVariant: 'light',
          headerClass: 'border-bottom-0',
          footerClass: 'border-top-0 pt-0'
        })
      }
    }
  }
}
</script>
