<template>
  <b-modal
    id="bodyscan-device-modal"
    ref="bodyscanDeviceModal"
    size="sm"
    :title="$t('app.bodyscan.connectDevice')"
    header-text-variant="light"
    header-class="border-bottom-0"
    header-bg-variant="success"
    footer-class="border-top-0 pt-0"
    centered
    no-close-on-backdrop
  >
    <div>
      <div
        id="reply"
        class="d-flex justify-content-center align-items-center text-center"
        style="font-size: 1rem; min-height: 106px;"
      >
        <b-spinner
          v-if="spinnerEnabled"
          variant="success"
          :label="$t('app.common.loading')"
        ></b-spinner>
        <div v-if="dataReceived" class="text-success">
          {{ $t('regional.charts.dataReceived') }}
        </div>
        <div v-if="dataError" class="text-danger">
          {{ dataErrorMessage }}
        </div>
      </div>
    </div>
    <div slot="modal-footer" class="w-100">
      <div class="float-right">
        <b-button variant="outline-secondary" @click="closeModal">
          {{ $t('fitness.modal.close') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      regions: [
        { field: 'rightBody', key: 'DX' },
        { field: 'upperRightLimb', key: 'RA' },
        { field: 'lowerRightLimb', key: 'RL' },
        { field: 'leftBody', key: 'SX' },
        { field: 'upperLeftLimb', key: 'LA' },
        { field: 'lowerLeftLimb', key: 'LL' },
        { field: 'upperBody', key: 'UB' },
        { field: 'lowerBody', key: 'LB' },
        { field: 'trunk', key: 'TR' }
      ],
      spinnerEnabled: false,
      dataReceived: false,
      dataError: false,
      dataErrorMessage: ''
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'bodyscan-device-modal') {
        this.connectDevice()
      }
    })
  },
  methods: {
    closeModal() {
      this.spinnerEnabled = false
      this.dataReceived = false
      this.dataError = false
      this.dataErrorMessage = ''
      this.$refs.bodyscanDeviceModal.hide()
    },
    insertResults(resultString) {
      const resultArr = resultString.split(';')
      this.regions.forEach(region => {
        const regionTitleIndex = resultArr.indexOf(region.key)
        document.querySelector('#' + region.field + 'Rz').value = parseFloat(
          resultArr[regionTitleIndex + 1]
        )
        document
          .querySelector('#' + region.field + 'Rz')
          .dispatchEvent(new Event('input'))
        document.querySelector('#' + region.field + 'Xc').value = parseFloat(
          resultArr[regionTitleIndex + 2]
        )
        document
          .querySelector('#' + region.field + 'Xc')
          .dispatchEvent(new Event('input'))
      })

      setTimeout(() => {
        this.closeModal()
      }, 1500)
    },
    connectDevice() {
      this.spinnerEnabled = true
      this.$ipc('bodyscan', {
        locale: this.$i18n.localeProperties.code
      })
        .then(response => {
          this.spinnerEnabled = false
          this.dataReceived = true
          this.insertResults(response.result.body)
        })
        .catch(error => {
          this.spinnerEnabled = false
          this.dataError = true
          this.dataErrorMessage = error.result.message
        })
    }
  }
}
</script>
