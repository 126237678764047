<template>
  <b-modal
    id="device-modal"
    ref="deviceModal"
    size="sm"
    :title="$t('regional.exam.connectDevice')"
    header-text-variant="light"
    header-class="border-bottom-0"
    header-bg-variant="primary"
    footer-class="border-top-0 pt-0"
    centered
    no-close-on-backdrop
  >
    <div>
      <div class="text-center" style="font-size: 1rem; min-height: 106px;">
        <div
          v-if="spinnerEnabled"
          class="d-flex justify-content-center align-items-center"
          style="min-height: 106px;"
        >
          <b-spinner
            variant="primary"
            :label="$t('app.common.loading')"
          ></b-spinner>
        </div>

        <div
          v-if="
            !spinnerEnabled &&
              response &&
              response.result &&
              response.result.body
          "
          v-html="response.result.body"
        ></div>

        <div
          v-if="
            !spinnerEnabled && error && error.result && error.result.message
          "
          class="text-danger"
          v-html="error.result.message"
        ></div>
      </div>
    </div>
    <div slot="modal-footer" class="w-100">
      <div class="float-right">
        <b-button
          :disabled="spinnerEnabled"
          variant="outline-secondary"
          @click="closeModal"
        >
          {{ $t('fitness.modal.close') }}
        </b-button>
        <b-button
          :disabled="spinnerEnabled || !response"
          variant="primary"
          class="ml-1"
          @click="handleSubmit"
        >
          {{ $t('app.forms.insert') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      spinnerEnabled: false,
      response: null,
      error: null
    }
  },
  mounted() {
    this.addScript()
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'device-modal') {
        this.connectDevice()
      }
    })
  },
  methods: {
    closeModal() {
      this.spinnerEnabled = false
      this.$refs.deviceModal.hide()
    },
    handleSubmit() {
      // paste rz and xc to exam modal
      document.querySelector('#rz-input').value = parseFloat(
        document.querySelector('#rz-span').innerHTML
      )
      document.querySelector('#rz-input').dispatchEvent(new Event('input'))

      document.querySelector('#xc-input').value = parseFloat(
        document.querySelector('#xc-span').innerHTML
      )
      document.querySelector('#xc-input').dispatchEvent(new Event('input'))

      this.closeModal()
    },
    addScript() {
      if (!document.getElementById('ipc-renderer-script')) {
        const renderer = document.createElement('script')
        renderer.setAttribute('id', 'ipc-renderer-script')
        renderer.setAttribute('type', 'text/javascript')
        renderer.innerHTML = "const ipc = require('electron').ipcRenderer;"
        document.body.appendChild(renderer)
      }
    },
    connectDevice() {
      this.spinnerEnabled = true
      this.response = null
      this.error = null

      this.$ipc('connectDevice', {
        locale: this.$i18n.localeProperties.code
      })
        .then(response => {
          this.response = response
        })
        .catch(error => {
          this.error = error
        })
        .finally(() => {
          this.spinnerEnabled = false
        })
    }
  }
}
</script>
