<template>
  <b-modal
    id="sync-modal"
    ref="syncModal"
    header-text-variant="light"
    header-class="border-bottom-0"
    header-bg-variant="success"
    footer-class="border-top-0 pt-0"
    centered
    ok-variant="success"
    cancel-variant="outline-secondary"
    :cancel-title="$t('app.common.cancel')"
    :busy="isBusy"
    no-close-on-backdrop
    @close="handleClose"
    @ok="handleOk"
  >
    <div slot="modal-header" class="text-light border-bottom-0 bg-success">
      <h5 class="modal-title">
        {{
          isBusy
            ? $t('app.offline.modal.busyTitle')
            : $t('app.offline.modal.title')
        }}
      </h5>
    </div>
    <div v-if="isBusy">
      <div
        v-if="hasDataToUpload"
        class="d-flex justify-content-center align-items-center"
        style="min-height: 66px;"
      >
        <b-spinner
          variant="success"
          label="Sync"
          style="width: 3rem; height: 3rem;"
        />
      </div>
      <div v-else>
        <p>
          {{ $t(`app.offline.syncing.${syncProgress.current}`) }} :
          {{ syncProgress[syncProgress.current].offset }} /
          {{ syncProgress[syncProgress.current].count }}
        </p>
        <b-progress :value="syncPercentage" variant="success" animated />
      </div>
    </div>
    <div v-else>
      <p>{{ $t('app.offline.modal.proceedMessage') }}</p>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      downloadDate: '',
      hasDataToUpload: false,
      isBusy: false,
      syncProgress: {
        current: 'sessions',
        sessions: {
          offset: 0,
          count: 0
        },
        groups: {
          offset: 0,
          count: 0
        },
        patients: {
          offset: 0,
          count: 0
        },
        exams: {
          offset: 0,
          count: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      accessToken: 'user/token',
      onLine: 'network/onLine',
      localAPI: 'network/localAPI'
    }),
    syncPercentage() {
      if (this.syncProgress.current === 'sessions') {
        const offset = this.syncProgress.sessions.offset
        const count = this.syncProgress.sessions.count
        return offset && count ? ((offset / count) * 100) / 4 : 0
      } else if (this.syncProgress.current === 'groups') {
        const offset = this.syncProgress.groups.offset
        const count = this.syncProgress.groups.count
        return offset && count ? ((offset / count) * 100) / 4 + 25 : 25
      } else if (this.syncProgress.current === 'patients') {
        const offset = this.syncProgress.patients.offset
        const count = this.syncProgress.patients.count
        return offset && count ? ((offset / count) * 100) / 4 + 50 : 50
      } else if (this.syncProgress.current === 'exams') {
        const offset = this.syncProgress.exams.offset
        const count = this.syncProgress.exams.count
        return offset && count ? ((offset / count) * 100) / 4 + 75 : 75
      } else {
        return 0
      }
    }
  },
  watch: {
    onLine(v) {
      if (v) {
        this.getSyncInfo()
      }
    }
  },
  created() {
    this.getSyncInfo()
  },
  mounted() {
    this.$root.$on('forceSync', this.getSyncInfo)
    const ipcRenderer = window.ipcRenderer || window.parent.ipcRenderer
    ipcRenderer.on('sync-progress', (event, data) => {
      this.syncProgress.current = data.name
      this.syncProgress[data.name] = {
        offset: data.offset,
        count: data.count
      }
    })
  },
  beforeDestroy() {
    this.$root.$off('forceSync')
    const ipcRenderer = window.ipcRenderer || window.parent.ipcRenderer
    ipcRenderer.removeAllListeners('sync-progress')
  },
  methods: {
    handleClose(bvModalEvt) {
      if (this.isBusy) {
        bvModalEvt.preventDefault()
      }
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.sync()
    },
    sync() {
      this.isBusy = true
      this.$axios
        .post(`${this.localAPI}/upload`, {
          accessToken: this.accessToken
        })
        .then(response => {
          if (response.data.synced > 0) {
            this.hasDataToUpload = false
            this.$axios
              .post(`${this.localAPI}/retrieve`, {
                accessToken: this.accessToken
              })
              .then(() => {
                this.$root.$emit('syncDone')
                // console.log('syncDone event sent')
                this.$refs.syncModal.hide()
                setTimeout(() => {
                  this.isBusy = false
                }, 1000)
              })
          } else {
            this.$root.$emit('syncDone')
            this.$refs.syncModal.hide()
            setTimeout(() => {
              this.isBusy = false
              this.hasDataToUpload = false
            }, 1000)
          }
        })
    },
    resetSyncProgress() {
      this.syncProgress = {
        current: 'sessions',
        sessions: {
          offset: 0,
          count: 0
        },
        groups: {
          offset: 0,
          count: 0
        },
        patients: {
          offset: 0,
          count: 0
        },
        exams: {
          offset: 0,
          count: 0
        }
      }
    },
    getSyncInfo(ignoreDownloadDate = false) {
      if (this.onLine) {
        this.$axios
          .post('/bodygram-backend/v1/settings/general/defaults', {
            accessToken: this.accessToken
          })
          .then(() => {
            this.$axios.get(`${this.localAPI}/get-sync-info`).then(response => {
              this.downloadDate = response.data.downloadDate
              this.hasDataToUpload = response.data.hasDataToUpload
              if (this.hasDataToUpload) {
                this.$bvModal.show('sync-modal')
              } else {
                this.isBusy = true
                this.$bvModal.show('sync-modal')
                this.$axios
                  .post(`${this.localAPI}/retrieve`, {
                    accessToken: this.accessToken,
                    ignoreDownloadDate
                  })
                  .then(() => {
                    this.$refs.syncModal.hide()
                    setTimeout(() => {
                      this.isBusy = false
                      this.resetSyncProgress()
                    }, 1000)
                  })
              }
            })
          })
      }
    }
  }
}
</script>
