const middleware = {}

middleware['auth'] = require('..\\middleware\\auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['bodygram'] = require('..\\middleware\\bodygram.js')
middleware['bodygram'] = middleware['bodygram'].default || middleware['bodygram']

middleware['crumb'] = require('..\\middleware\\crumb.js')
middleware['crumb'] = middleware['crumb'].default || middleware['crumb']

middleware['fitness'] = require('..\\middleware\\fitness.js')
middleware['fitness'] = middleware['fitness'].default || middleware['fitness']

middleware['keto'] = require('..\\middleware\\keto.js')
middleware['keto'] = middleware['keto'].default || middleware['keto']

middleware['regional'] = require('..\\middleware\\regional.js')
middleware['regional'] = middleware['regional'].default || middleware['regional']

middleware['router'] = require('..\\middleware\\router.js')
middleware['router'] = middleware['router'].default || middleware['router']

export default middleware
