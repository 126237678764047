const state = () => ({
  bcm: 'Body Cell Mass (BCM)',
  bcmi: 'Body Cell Mass Index (BCMI)',
  bmi: 'Body Mass Index (BMI)',
  bmr: 'Basal Metabolic Rate (BMR)',
  ecw: 'Extra Cellular Water (ECW)',
  ffm: 'Fat-Free Mass (FFM)',
  fm: 'Fat Mass (FM)',
  pha: 'Phase Angle (PhA)',
  tbw: 'Total Body Water (TBW)',
  asmm: 'Appendicular Skeletal Muscle Mass (ASMM)',
  asmmi: 'Appendicular Skeletal Muscle Mass Index (ASMMI)',
  naeKe: 'Na/K',
  icw: 'Intra Cellular Water (ICW)',
  mm: 'Skeletal Muscle Mass (SMM)',
  tdee: 'Total Daily Energy Expenditure (TDEE)',
  weight: 'Weight',
  height: 'Height',
  alst: 'Arm Lean Soft Tissue (aLST)',
  llst: 'Leg Lean Soft Tissue (lLST)',
  daysOfWeek: {
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday'
  },
  units: {
    asmm: 'kg',
    asmmi: 'kg/m²',
    bcm: 'kg',
    bcmi: 'kg/m²',
    bmi: 'kg/m²',
    bmr: 'kcal',
    ecw: 'L',
    ffm: 'kg',
    fm: 'kg',
    ffmi: 'kg/m²',
    fmi: 'kg/m²',
    icw: 'L',
    naeKe: '',
    pha: '°',
    mm: 'kg',
    smm: 'kg',
    tbw: 'L',
    spa: '°',
    smi: 'kg/m²',
    tdee: 'kcal',
    idealWeight: 'kg',
    weight: 'kg',
    height: 'cm',
    alst: 'kg',
    llst: 'kg'
  }
})
const getters = {
  daysOfWeek: state => state.daysOfWeek,
  units: state => state.units
}
export default {
  state,
  getters,
  namespaced: true
}
